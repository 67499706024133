import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import {
    ThriveFullscreenLoading,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ROUTES } from './routes'

const CoachingPage = lazy(() => import('../pages/home/CoachingPage'))
const DailyExperience = lazy(
    () => import('../pages/dailyExperience/DailyExperience')
)
const WelcomePage = lazy(() => import('../pages/welcome/Welcome'))
const UploadCoacheesPage = lazy(() => import('../pages/admin/UploadCoachees'))
const OnBoardingPage = lazy(() => import('../pages/onBoarding'))
// coach
const MySchedulePage = lazy(() => import('../pages/coach/MySchedule'))

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<ThriveFullscreenLoading />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.UPLOAD_COACHEES}
                            component={UploadCoacheesPage}
                        />
                        <ProtectedRoute
                            path={ROUTES.MYSCHEDULE}
                            component={MySchedulePage}
                        />
                        <ProtectedRoute
                            path={ROUTES.WELCOME}
                            component={WelcomePage}
                        />
                        <ProtectedRoute
                            path={ROUTES.HOME}
                            component={DailyExperience}
                        />
                        <ProtectedRoute
                            path={ROUTES.ONBOARDING}
                            component={OnBoardingPage}
                        />
                        <ProtectedRoute
                            path={ROUTES.COACHING}
                            component={WelcomePage}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
